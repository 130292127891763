import logo from './logo.svg';
import './App.css';
import bg from './assets/bg.jpg';
import lxwm from './assets/lxwm.pic.jpg';
import gywm from './assets/gywm.pic.jpg';
import ffzx from './assets/ffzx.pic.jpg';
import qrcode from './assets/qrcode.jpg';
import { useState } from 'react';

function App() {
  const [router, setRouter] = useState('main')
  const goPage = (page) => {
    if (router === page) return;
    setRouter(page);
  };
  return (
    <div className="App">
      <div className='admin-button' onClick={() => goPage('main')}></div>
      {router === 'main' && <img src={bg} style={{ width: '100%' }} />}
      {router === 'lxwm' && <img src={lxwm} style={{ width: '100%' }} />}
      {router === 'gywm' && <img src={gywm} style={{ width: '100%' }} />}
      {router === 'ffzx' && <img src={ffzx} style={{ width: '100%' }} />}
      {router === 'qrcode' && <img src={qrcode} style={{ width: '100%' }} />}
      <div className='menu'>
        <div onClick={() => goPage('ffzx')}>付费资讯</div>
        <div onClick={() => goPage('gywm')}>关于我们</div>
        <div onClick={() => goPage('lxwm')}>联系我们</div>
      </div>
      <div className='show-qr-btn1' onClick={() => goPage('qrcode')}></div>
      <div className='show-qr-btn2' onClick={() => goPage('qrcode')}></div>
      <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" className='icp'>京ICP备17000479号-1</a>
    </div>
  );
}

export default App;
